import {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import clsx from "clsx";
import LogoImgMobile from '../../assets/logo/logo-mobile.png';
import {ReactComponent as HamburgerButtonIcon} from '../../assets/icons/burger-menu-icon.svg';
import Button from "../elements/Button/Button";
import styles from './TopBar.module.scss'
import {QuizContext} from "../../contexts/QuizContext";
import {START_VIEW} from "../../config/constants";
import {DemoContext} from "../../contexts/DemoContext";
import {gsap} from "gsap";
import formatCurrency from "../../utils/formatCurrency";
import SoundManager from "../../utils/SoundManager";

interface Props {
    cycleOpen: () => void;
    displayBalance: boolean;
    resetBetweenRounds: () => void;
}

export default function TopBar({cycleOpen, displayBalance, resetBetweenRounds}: Props) {
    const {resetDemoBetweenRounds} = useContext(DemoContext);
    const {userBalance, tokenData, isReplay} = useContext(AppContext);
    const {
        setCurrentView,
        userIsPlayingQuiz,
        currentView,
    } = useContext(QuizContext);
    const {isDemo, funModebalance, isFunMode} = useContext(DemoContext);

    const [total, setTotal] = useState<string>('');
    const [totalFunModeBalance, setTotalFunModeBalance] = useState<string>('');
    const previousBalance = useRef<any>();
    const balance = useRef<any>();
    const funModeBalanceRef = useRef<any>();

    const bounceBalance: () => void = () => {
        gsap.to(balance.current, {
            scale: 1.2,
            x: 5,
            ease: 'Sine.easeOut',
            duration: 0.4,
        });
        gsap.to(balance.current, {
            scale: 1,
            x: 0,
            delay: 0.1,
            ease: 'Sine.easeOut',
        });
    };

    const animateBalance: () => void = () => {
        gsap.to(previousBalance.current, {
            value: userBalance?.totalValue,
            duration: 0.5,
            ease: 'power2.out',
            onUpdate: () => {
                const totalString: string = formatCurrency(previousBalance.current.value);
                setTotal(totalString);

                bounceBalance();
            },
        });
    };

    const animateFunModeBalance: () => void = () => {
        gsap.to(funModeBalanceRef.current, {
            value: funModebalance,
            duration: 0.5,
            ease: 'power2.out',
            onUpdate: () => {
                const totalString: string = formatCurrency(funModeBalanceRef.current.value);
                setTotalFunModeBalance(totalString);
            },
        });
    };

    useEffect(() => {
        if (!userIsPlayingQuiz) {
            SoundManager.instance.stopVO();
            SoundManager.instance.playVO('menu-music');
        } else {
            SoundManager.instance.stopVO();
            SoundManager.instance.playVO('quiz-music');
        }
    }, [userIsPlayingQuiz]);

    useEffect(() => {
        if (userBalance?.totalValue !== null) {
            setTotal((oldVal: string) => {
                if (oldVal) {
                    const oldValNumber: number = Number(oldVal.replace(/[^0-9.]+/g, ''));

                    previousBalance.current = {
                        value: oldValNumber,
                    };
                    if (userBalance !== null) {
                        if (oldValNumber !== userBalance.totalValue) {
                            animateBalance();
                        }
                    }
                }

                if (userBalance !== null) {
                    return formatCurrency(userBalance.totalValue);
                }
                return '';
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userBalance]);

    useEffect(() => {

        if (funModebalance !== null) {
            const currentFunModeBalance: number = funModebalance;
            setTotalFunModeBalance((oldVal: string) => {
                if (oldVal) {
                    const oldValNumber: number = Number(oldVal.replace(/[^0-9.]+/g, ''));
                    funModeBalanceRef.current = {
                        value: oldValNumber,
                    };

                    if (oldValNumber !== currentFunModeBalance) {
                        animateFunModeBalance();
                    }
                }

                if (funModebalance !== null) {
                    return formatCurrency(funModebalance);
                }
                return '';
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funModebalance]);

    return (
        <div className={clsx(styles.wrapper)}>
                <Button isQuaternary className={styles.hamburgerButtonBox} onClick={(event) =>{
                    if (isReplay || userIsPlayingQuiz) {
                        event.preventDefault();
                    } else {
                        cycleOpen();
                    }
                }}>
                    <HamburgerButtonIcon/>
                </Button>

            <a onClick={(event) => {
                if (isReplay) {
                    event.preventDefault();
                } else {
                    if (isDemo) {
                        resetDemoBetweenRounds();
                        setCurrentView(START_VIEW);
                    }
                    resetBetweenRounds();
                }
            }}
               className={clsx(styles.logoContainer, {
                [styles.isStartView]: currentView === START_VIEW,
                [styles.isReplay]: isReplay,
            })}>
                <div className={clsx(styles.logo, {
                    [styles.isStartView]: currentView === START_VIEW,
                })}>
                    <img
                        src={LogoImgMobile}
                        alt="Logo"
                    />
                </div>
            </a>

            <div className={styles.balanceWrapper}>
                {isFunMode && !tokenData?.access_token ? (
                    funModebalance !== null && (
                        <div className={clsx(styles.innerWrapper, {[styles.hideBalance]: !displayBalance})}>
                            <span className={styles.balanceLabel}>Balance</span>
                            <span ref={funModeBalanceRef} className={styles.balance}>
                                {totalFunModeBalance}
                            </span>
                        </div>
                    )
                ) : (
                    userBalance !== null && (
                        <div className={clsx(styles.innerWrapper, {[styles.hideBalance]: !displayBalance})}>
                            <span className={styles.balanceLabel}>Balance</span>
                            <span ref={balance} className={styles.balance}>
                                {total}
                            </span>
                        </div>
                    )
                )}
            </div>

        </div>
    )
}