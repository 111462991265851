export const en = {
  translation: {
    soundButton: {
      soundOff: 'Sound off',
      soundOn: 'Sound on',
    },
    menu: {
      play: 'PLAY',
      tryDemo: 'TRY A DEMO ROUND',
      topScores: 'TOP SCORES',
      prizes: 'PRIZES',
      howItWorks: 'HOW IT WORKS',
      achievements: 'ACHIEVEMENTS',
      statistics: 'STATISTICS',
    },
    start: {
      heading: 'Welcome',
      subHeading: 'Time to test your sport quiz skills - and your luck to win cash!',
      playButton: 'PLAY',
      gameName: 'SportsMaster',
      demoButton: 'TRY A DEMO ROUND',
      seeTopScoreButton: 'See top scores',
      seePrizesButton: 'See prizes',
    },
    tutorial: {
      heading: 'How it works',
      firstHeading: 'How to play',
      firstParagraph: 'Sport Master is both a trivia game and a lottery. Here you win based on both skill and pure luck.',
      secondHeading: 'How the Quiz works',
      secondParagraph: 'Collect points by answering 7 questions. You get more points if you answer quickly. Use shortcuts if needed:',
      secondParagraph1: 'You can use one shortcut per round. Extra time gives you more time to answer the question, 50/50 removes two of the incorrect answers for you, and New question replaces the question with a new one. Through campaigns and offers, you can replenish your shortcuts.',
      thirdHeading: 'How the Lottery works',
      thirdParagraph: 'Lottery coins are flipped between each question. 3 matching symbols yield a win up to one million!',

    },
    quiz: {
      purchase: {
        heading: 'Choose your bet',
        topPrizeSubHeading: 'Top cash prize',
        topPrizeAmount: '1 000 000 $',
        purchaseCompleteText: 'Payment succeeded',
        playButton: 'PLAY',
        gameName: 'SportsMaster',
      },
      countdown: {
        header: 'The Quiz',
        subHeader: 'Question {{index}} of {{totalQuestions}}',
        category: 'Next category:',
        countdownText: 'Get ready in:',
      },
      question: {
        questionCounter: 'Question {{index}} of {{total}}',
      },
      flipCoin: {
        heading: 'The cash chance',
        questionCounter: 'Question {{index}} of {{total}}',
        subHeading: 'Flip the coin and reveal a prize coin!',
        comment: 'Three matching coins yield a win',
        nextQuestion: 'Next question',
      },
      quizResult: {
        header: 'My quiz result',
        scoreHeader: 'Total:',
        questionNumber: 'Question {{index}}',
        correctLabel: 'Correct',
        inCorrectLabel: 'Wrong',
        answer: 'answer',
        buttonText: 'Flip the last cash coin',
        timeoutLabel: 'Time out',
      },
      summary: {
        heading: 'CONGRATS!',
        subHeading: 'You have gotten three of a kind!',
        winningHeading: 'You have won!',
        demoSubHeading: "Demo game won",
        playButton: 'Play again',
        closeButton: 'Close game',
      },
      lotteryResult: {
        header: 'The quiz',
        replayHeader: 'Lottery Result',
        closeGame: 'Close game',
        personalBest: 'Your best this week:',
        myPoints: 'My score',
        topscoreButtonText: 'Go to leaderboard',
        lotteryHeader: 'Prize:',
        todaysPlacement: 'Place today:',
        weeksPlacement: 'Place this week:',
        playAgainText: 'You want to play another round?',
        playAgain: 'Play another round',
        demoGameWon: 'Demo game won!',
        demoGameLost: 'No demo prize won',
        noPrize: 'No prize won',
      },
    },
    prizes: {
      prizesLabel: 'Quiz Prizes',
      prizeplansLabel: 'Lottery Prizes',
      prizeValue: 'Prize value:',
      firstPlace: '1 place',
      secondPlace: '2 place',
      thirdPlace: '3 place',
      firstPrize: '$1000',
      secondPrize: '$150',
      thirdPrize: '$50',
      firstPrizeDesc: 'Signed american football shirt.',
      secondPrizeDesc: 'Tickets to football match.',
      thirdPrizeDesc: 'A brand new Football.',
    },
    topscore: {
      today: 'Today',
      week: 'Week',
      leaderboard: 'Leaderboard',
      quizWinnersLabel: 'Previous winners',
      competitionEnd: 'competition ends:',
      seePrizes: 'See prizes',
      quizWinners: {
        tableHeaderDate: 'Date',
        tableHeaderWinner: 'Winner',
        tableHeaderScore: 'Score',
        weekNumber: 'Week {{weekNumber}}',
        noWinner: 'No winner',
      },
    },

    bonusModal: {
      purchaseConfirmation: {
        firstParagraph1: 'You will have',
        firstParagraph2: 'reached',
        firstParagraph3: 'your bonus!',
        firstParagraph4: 'left to bonus!',
        bonusPayoutText: 'Bonus!',
        voucherText: '{{amount}} free games!',
      },
      receipt: {
        firstParagraph1: 'You have',
        firstParagraph2: 'reached',
        firstParagraph3: 'your bonus!',
        firstParagraph4: 'left to bonus!',
        moneyPayout: '{{amount}} is deposited to your wallet.',
        voucherPayout: 'Your free games has been payed out.',
        percentPayout: 'Your bonus has been payed out!',
      },
      purchaseView: {
        purchaseView: 'left to bonus!',
        bonusPayoutText: 'Bonus!',
        voucherText: '{{amount}} free games!',
      },
    },
    prizeplan: {
      title: 'Prize plan',
      text: 'The lottery consists of 1 000 000 lottery tickets per stake each with a total of 1 000 000 winning tickets.',
      heading1: 'Quantity',
      heading2: 'Prize',
      jackpot: 'Million chance',
      returnToPlayer: 'Return to player',
      payoutPercentage: '%',
      showGemValues: 'Show gem values',
      million: 'Million chance',
    },
    activeGame: {
      skipButton: 'skip',
    },
    onboarding: {
      skip: 'skip',
      continue: 'continue',
      heading: 'How to play',
      text1:
          'Collect points by answering 7 questions. You get more points if you answer quickly. Use shortcuts if needed:',
      heading2: 'How the Quiz works',
      text2:
          'You can use one shortcut per round. Extra time gives you more time to answer the question, 50/50 removes two of the incorrect answers for you, and New question replaces the question with a new one. Through campaigns and offers, you can replenish your shortcuts.',
      heading3: 'How the Lottery works',
      text3:
          'Lottery coins are flipped between each question. 3 matching symbols yield a win up to one million!',
    },
    modal: {
      login: 'Sign in',
      logout: 'Logout',
      demo: 'Demo play',
      loginTitle: 'Sign in',
      loginContent:
          'You need to sign in to be able to play and win. You can play the demo first if you want!',
      walletTitle: "You don't have enough funds",
      walletContent: '',
      sessionTimeoutTitle: 'Your session has timed out',
      sessionTimeoutContent: 'Please logon again to continue playing. If you were in the middle of playing a game, your game can be played once you have logged on again.',
      sessionTimeoutButton: 'Exit',
      unfinishedTitle: 'Resume your game?',
      unfinishedContent:
          'You have an unfinished ticket. Do you want to play it now?',
      generalErrorTitle: 'Something went wrong',
      generalErrorContent: 'Something went wrong. Try again.',
      close: 'Close',
      resume: 'Play',
      viewResult: 'Check result',
      ok: 'Ok',
    },
    amount: '{{amount}}',
    currencyFormat: 'EUR',
    currencyLocale: 'en-GB',
    errors: {
      default: 'Something went wrong!',
      userBalance: 'Could not fetch balance, reload the page to try again.',
      purchase: 'A problem occured during purchase, try again.',
      demoPurchase: 'Something went wrong with your demo ticket, try again.',
      gameStop: 'You have game stop activated.',
      limitNotSet:
          'You have to set your game limits before you purchasing tickets.',
      noAvailableTickets: 'There are no available tickets to purchase.',
      getTicketError: 'Something went wrong when collecting your ticket.',
      somethingWrongWithTicket: 'Unknown error with your ticket.',
      replayLoginError: 'You must login to replay your ticket.',
    },
  },
};
