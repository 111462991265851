import {useContext} from "react";
import {AnimatePresence, motion} from "framer-motion";
import Links from './helpers/Links'
import {ReactComponent as CloseMenuButtonIcon} from '../../assets/icons/close-menu-button-icon.svg';
import {AppContext} from "../../contexts/AppContext";
import Button from "../elements/Button/Button";
import styles from './Menu.module.scss'

interface Props {
    isOpen: boolean;
    cycleOpen: () => void;
}

export default function Menu({isOpen, cycleOpen}: Props) {
    const {tokenData} = useContext(AppContext);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.aside
                    className={styles.menuBox}
                    initial={{width: 0}}
                    animate={{width: 230,
                        transition: {duration: 0.45}
                }}
                    exit={{
                        width: 0,
                        transition: {delay: 0.2, duration: 0.25}
                    }}>

                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {delay: 0.5}}}
                        exit={{opacity: 0}}
                    >
                        <Button
                            className={styles.closeButton}
                            isModalCloseButton
                            onClick={cycleOpen}
                        >
                            <CloseMenuButtonIcon/>
                        </Button>
                    </motion.div>
                        <Links isPlayVisible={!!tokenData?.access_token} cycleOpen={cycleOpen}/>
                </motion.aside>
            )}
        </AnimatePresence>

    )
}